import React, { useState, Fragment } from 'react';
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import './Chart.css';
import { CustomToolTip } from './CustomToolTip';

export const DashboardUsageChart = ({ meter }) => {
  let tickDays = [0, 1, 2, 3, 4, 5, 6, 7];
  let tickDaysTime = tickDays.map((t) => t * 1000 * 60 * 60 * 24);
  let convertedReadings = [];
  let experimentReadings = [];
  let baselineReadings = [];
  let experimentStart = meter.experimentStart
    ? new Date(meter.experimentStart).valueOf()
    : undefined;
  let baselineAverage = 0;
  let experimentAverage = 0;
  let experimentDifference = 0;
  let experimentDifferenceNegative = false;

  const useStyles = makeStyles(({ palette }) => ({
    usageChart: {
      width: '100%',
      height: '75vh',
      backgroundColor: palette.meterTypeColor,
    },
    heading: {
      fontSize: '1.7em',
    },
    usageChartHeader: {
      color: 'white',
      backgroundColor: palette.meterTypeColor,
      textTransform: 'capitalize',
    },
  }));

  const classes = useStyles(meter);
  const [expanded, setExpanded] = useState(false);

  const toggleExpansionPanel = () => {
    setExpanded(!expanded);
  };

  if (meter.readings) {
    const validReadings = meter.readings.filter((reading) => {
      return !reading.deleted;
    });
    baselineReadings = validReadings.filter((reading) => {
      reading.time = new Date(reading.time).valueOf();
      // If experiment start is undefined, all comparisons
      // are false, so nothing is returned.
      return reading.time <= experimentStart;
    });
    experimentReadings = validReadings.filter((reading) => {
      reading.time = new Date(reading.time).valueOf();
      // If experiment start is undefined, all comparisons
      // are false, so nothing is returned.
      return reading.time > experimentStart;
    });
    convertedReadings = validReadings.map((reading) => {
      reading.time = new Date(reading.time).valueOf();
      let firstReadingStart = new Date(validReadings[0].time).valueOf();
      if (!experimentStart || reading.time < experimentStart) {
        reading.plotTime = reading.time - firstReadingStart;
        reading.baseline = reading.value - validReadings[0].value;
        reading.yValue = reading.baseline;
      } else if (
        experimentStart &&
        experimentReadings &&
        experimentReadings.length > 0
      ) {
        reading.plotTime = reading.time - experimentReadings[0].time;
        reading.experiment = reading.value - experimentReadings[0].value;
        reading.yValue = reading.experiment;
      }
      return reading;
    });
  }
  if (meter.expectedUsage) {
    for (let i = 0; i < tickDays.length; i++) {
      convertedReadings.push({
        plotTime: tickDaysTime[i],
        expected: meter.expectedUsage * tickDays[i],
        yValue: meter.expectedUsage * tickDays[i],
      });
    }
  }
  
  if (baselineReadings.length && baselineReadings[0].value && baselineReadings.length > 1) {
    console.log(baselineReadings);
    baselineAverage = (baselineReadings[baselineReadings.length-1].value - baselineReadings[0].value) / (baselineReadings.length-1);
    console.log(baselineAverage);
  }
  if (experimentReadings.length && experimentReadings[0].value && experimentReadings.length > 1) {
    console.log(experimentReadings);
    experimentAverage = (experimentReadings[experimentReadings.length-1].value - experimentReadings[0].value) / (experimentReadings.length - 1);
    console.log(experimentAverage);
  }
  if (experimentAverage !== 0 && baselineAverage !== 0) {
    experimentDifferenceNegative = (baselineAverage > experimentAverage) ? true : false;
    experimentDifference = 100 * experimentAverage / baselineAverage;
  }
  

  const Overlay = () => (
    <div style={{
      position: 'absolute',
      top: '10%', // Adjust based on your layout needs
      left: '10%', // Adjust based on your layout needs
      // width: '150px',
      // height: '100px',
      background: 'rgba(255, 255, 255, 0.8)',
      color: 'black',
      display: 'flex',
      flexDirection: 'column', // Use column to stack children vertically
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '5px',
      padding: '10px', // Add padding for better text spacing
      boxSizing: 'border-box', // Include padding in width/height calculations
    }}>
      <div>
        <strong>Average daily usage</strong>
      </div>
      <div>
        <strong>Baseline: </strong>
        {baselineAverage.toFixed(2)} {meter.unit}
      </div>
      <div>
        <strong>Experiment: </strong>
        {experimentAverage.toFixed(2)} {meter.unit}
      </div>
      <div>
        <strong>% of Baseline: </strong>
        {/* {experimentDifferenceNegative ? '-' : ''} */}
        {experimentDifference.toFixed(2)} %
      </div>
    </div>
  );

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        className={classes.usageChartHeader}
        IconButtonProps={{
          onClick: toggleExpansionPanel,
        }}
        expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>{`${meter.type} Usage Chart`}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.usageChart}>
        <Fragment>
          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <ResponsiveContainer>
              <LineChart
                data={convertedReadings}
                margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid
                  vertical={false}
                  stroke="white"
                  strokeOpacity="0.6"
                  strokeDasharray="2 2 2"
                />

                <XAxis
                  dataKey="plotTime"
                  type="number"
                  padding={{ left: 40, right: 40 }}
                  stroke="white"
                  style={{ fontSize: '1em' }}
                  domain={['dataMin', 'dataMax']}
                  tickLine={{ stroke: 'white' }}
                  axisLine={{ stroke: 'white' }}
                  ticks={tickDaysTime}
                  tickFormatter={(t) => t / 1000 / 60 / 60 / 24}
                >
                  <Label
                    value="24-hour periods"
                    fill="white"
                    position="insideBottomRight"
                    offset={0}
                  />
                </XAxis>

                <YAxis
                  type="number"
                  stroke="white"
                  style={{ fontSize: '1em' }}
                  domain={['auto', 'auto']}
                  tickLine={false}
                  padding={{ top: 20, bottom: 30 }}
                  axisLine={false}
                >
                  <Label
                    value={meter.unit}
                    angle={-90}
                    position="insideLeft"
                    fill="white"
                  />
                </YAxis>

                <Tooltip
                  active={false}
                  content={<CustomToolTip />}
                  cursor={false}
                  meter={meter}
                />

                <Legend align="left" wrapperStyle={{ color: 'white' }} />

                <Line
                  isAnimationActive={false}
                  dataKey="expected"
                  type="monotone"
                  stroke="#F9F935"
                  strokeWidth="2px"
                  dot={{ stroke: '#F9F935', strokeWidth: 2, r: 5 }}
                  activeDot={{ stroke: '#F9F935', strokeWidth: 3, r: 7 }}
                />

                <Line
                  isAnimationActive={false}
                  dataKey="baseline"
                  type="monotone"
                  stroke="black"
                  strokeWidth="2px"
                  dot={{ stroke: 'black', strokeWidth: 2, r: 5 }}
                  activeDot={{ stroke: 'black', strokeWidth: 3, r: 7 }}
                />

                <Line
                  isAnimationActive={false}
                  dataKey="experiment"
                  type="monotone"
                  stroke="white"
                  strokeWidth="2px"
                  dot={{ stroke: 'white', strokeWidth: 2, r: 5 }}
                  activeDot={{ stroke: 'white', strokeWidth: 3, r: 7 }}
                />
              </LineChart>
            </ResponsiveContainer>
            <Overlay></Overlay>
          </div>
        </Fragment>
      </AccordionDetails>
    </Accordion>
  );
};
