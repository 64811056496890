import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import {
  AddCircleOutline,
  BuildOutlined,
  CheckCircle,
  EcoOutlined,
  EmojiObjectsOutlined,
  Help,
  HighlightOff,
  PowerOutlined,
  ThumbUp,
  ToysOutlined,
  WavesRounded,
  WbIncandescentOutlined,
  Done,
} from '@material-ui/icons';
import Axios from 'axios';
import React from 'react';
import {useStepsStyles} from '../useStepsStyles';

export function ChooseSolutionComponent({
  meter,
  setMeter,
  setActiveStep,
  disableDataCollectionBtn,
  isMeterOwner,
}) {
  const classes = useStepsStyles(meter);

  const [isChecked, setIsChecked] = React.useState({
    fixLeaksStrategy: false,
    lightEfficientlyStrategy: false,
    WaterTemperatureStrategy: false,
    outSideWaterStrategy: false,
    heatingCoolingStrategy: false,
    plugLoadStrategy: false,
    laundryStrategy: false,
    other: false,
  });

  const [otherText, setOtherText] = React.useState(meter.experimentText);

  const [onSuccess, setOnSuccess] = React.useState(false);

  const otherIsCheckedAndTextFieldIsFilled =
    isChecked.other && !otherText ? true : false;

  const noneSelected = Object.keys(isChecked).every((e) => !isChecked[e])
    ? true
    : false;

  const isDisabled =
    !isMeterOwner ||
    noneSelected ||
    otherIsCheckedAndTextFieldIsFilled;

  React.useEffect(() => {
    let isCheckedUpdate = {};
    if (meter.experiments.length) {
      meter.experiments.map((arg) => (isCheckedUpdate[arg] = true));
      return setIsChecked({...isChecked, ...isCheckedUpdate});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meter]);

  const handleCheckChange = (e) => {
    setIsChecked({...isChecked, [e.target.name]: e.target.checked});
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newMeter = {
      ...meter,
      currentStep: 4,
      experiments: Object.keys(isChecked).filter((k) => isChecked[k]),
      experimentText: Object.keys(isChecked).filter(
        (k) => isChecked.other && isChecked[k],
      ).length
        ? otherText
        : undefined,
    };

    try {
      const result = await Axios.post(`/api/meter/${meter._id}`, newMeter);

      setMeter(result.data);

      if (result.status === 200) {
        setOnSuccess(true);
      }
      setTimeout(() => {
        setActiveStep((prevState) => prevState + 1);
      }, 600);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <FormControl>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Grid container direction="column" spacing={1}>
                  <FormControlLabel
                    label="Find and stop waste"
                    control={
                      <Checkbox
                        checked={isChecked.fixLeaksStrategy}
                        name="fixLeaksStrategy"
                        onChange={handleCheckChange}
                        icon={<BuildOutlined classes={{root: classes.btn}} />}
                        checkedIcon={
                          <Done classes={{root: classes.btnSuccess}} />
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    label="Light spaces efficiently"
                    control={
                      <Checkbox
                        checked={isChecked.lightEfficientlyStrategy}
                        name="lightEfficientlyStrategy"
                        onChange={handleCheckChange}
                        icon={
                          <EmojiObjectsOutlined classes={{root: classes.btn}} />
                        }
                        checkedIcon={
                          <Done classes={{root: classes.btnSuccess}} />
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    label="Don't over heat water"
                    control={
                      <Checkbox
                        checked={isChecked.WaterTemperatureStrategy}
                        name="WaterTemperatureStrategy"
                        onChange={handleCheckChange}
                        icon={
                          <WbIncandescentOutlined
                            classes={{root: classes.btn}}
                          />
                        }
                        checkedIcon={
                          <Done classes={{root: classes.btnSuccess}} />
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    label="Reduce outdoor water use"
                    control={
                      <Checkbox
                        checked={isChecked.outSideWaterStrategy}
                        name="outSideWaterStrategy"
                        onChange={handleCheckChange}
                        icon={<EcoOutlined classes={{root: classes.btn}} />}
                        checkedIcon={
                          <Done classes={{root: classes.btnSuccess}} />
                        }
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid
                  container
                  direction="column"
                  spacing={1}
                  classes={{root: classes.column}}
                >
                  <FormControlLabel
                    label="Reduce heating and cooling needs"
                    control={
                      <Checkbox
                        checked={isChecked.heatingCoolingStrategy}
                        name="heatingCoolingStrategy"
                        onChange={handleCheckChange}
                        icon={<ToysOutlined classes={{root: classes.btn}} />}
                        checkedIcon={
                          <Done classes={{root: classes.btnSuccess}} />
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    label="Reduce plug-load"
                    control={
                      <Checkbox
                        checked={isChecked.plugLoadStrategy}
                        name="plugLoadStrategy"
                        onChange={handleCheckChange}
                        icon={<PowerOutlined classes={{root: classes.btn}} />}
                        checkedIcon={
                          <Done classes={{root: classes.btnSuccess}} />
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    label="Reduce indoor water use"
                    control={
                      <Checkbox
                        checked={isChecked.laundryStrategy}
                        name="laundryStrategy"
                        onChange={handleCheckChange}
                        icon={<WavesRounded classes={{root: classes.btn}} />}
                        checkedIcon={
                          <Done classes={{root: classes.btnSuccess}} />
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    label="Other"
                    control={
                      <Checkbox
                        checked={isChecked.other}
                        name="other"
                        onChange={handleCheckChange}
                        icon={
                          <AddCircleOutline classes={{root: classes.btn}} />
                        }
                        checkedIcon={
                          <Done classes={{root: classes.btnSuccess}} />
                        }
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                value="updatedMeter"
                className={classes.root}
                variant="outlined"
                disabled={isDisabled}
              >
                {isDisabled ? (
                  <HighlightOff style={{color: 'grey'}} />
                ) : onSuccess ? (
                  <ThumbUp style={{color: 'white'}} />
                ) : (
                  <CheckCircle style={{color: 'white'}} />
                )}
              </Button>
              <Button
                className={classes.root}
                variant="outlined"
                component="a"
                href="https://meterhero.com/choose"
                target="_blank"
                rel="noopener"
              >
                <Help style={{color: 'white'}} />
              </Button>
              {isChecked.other ? (
                <TextField
                  classes={{root: classes.otherText}}
                  label="Describe what you did"
                  name="otherText"
                  value={otherText}
                  onChange={(e) => setOtherText(e.target.value)}
                  variant="outlined"
                  size="small"
                  disableGutters
                />
              ) : (
                ''
              )}
            </Grid>
          </FormControl>
        </Grid>
      </form>
    </React.Fragment>
  );
}

// export default ChooseSolutionComponent;
