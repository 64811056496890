import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Step,
  StepButton,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import {ExpandMore as ExpandMoreIcon} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {getStepContent, getSteps} from '../stepper-steps/getSteps';

const useStyles = makeStyles(({palette}) => ({
  stepper: {
    width: '100%',
  },
  heading: {
    fontSize: '1.7em',
  },
  stepperHeader: {
    color: 'white',
    backgroundColor: palette.meterTypeColor,
  },
  stepButton: {
    '& .MuiStepIcon-active': {
      opacity: 1,
      color: palette.meterTypeColor,
    },
    '& .MuiStepIcon-completed': {
      color: palette.meterTypeColor,
      opacity: 0.6,
    },
  },
  stepIcon: {
    color: palette.meterTypeColor,
    opacity: 0.6,
  },
  stepIconText: {
    display: 'none',
  },
}));

export const ProjectPlanStepper = ({meter, setMeter, user}) => {
  const classes = useStyles(meter);
  const [activeStep, setActiveStep] = useState(-1);
  const [expanded, setExpanded] = useState(true);

  const [
    disableDataCollectionBtn,
    setDisableDataCollectionBtn,
  ] = React.useState(false);

  React.useEffect(() => {
    if (meter.currentStep) {
      setActiveStep(meter.currentStep);
    } else {
      setActiveStep(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meter._id]);

  const isMeterOwner = meter.user === user._id ? true : false;

  const steps = getSteps();

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const toggleExpansionPanel = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={classes.stepper}>
      <Accordion expanded={expanded}>
        <AccordionSummary
          className={classes.stepperHeader}
          IconButtonProps={{
            onClick: toggleExpansionPanel,
          }}
          expandIcon={<ExpandMoreIcon style={{color: 'white'}} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Project Plan</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="div" classes={{root: classes.stepper}}>
            <Stepper nonLinear activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => {
                const stepProps = {};
                if (index < activeStep) {
                  stepProps.completed = true;
                }

                return (
                  <Step key={label} {...stepProps}>
                    <StepButton
                      classes={{
                        root: classes.stepButton,
                      }}
                      onClick={handleStep(index)}
                    >
                      <StepLabel
                        StepIconProps={{
                          classes: {
                            root: classes.stepIcon,
                            text: classes.stepIconText,
                          },
                        }}
                      >
                        {label}
                      </StepLabel>
                    </StepButton>
                    <StepContent>
                      <Typography component="div">
                        {getStepContent(activeStep, {
                          meter,
                          setMeter,
                          isMeterOwner,
                          disableDataCollectionBtn,
                          setActiveStep,
                          setDisableDataCollectionBtn,
                        })}
                      </Typography>
                    </StepContent>
                  </Step>
                );
              })}
            </Stepper>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

ProjectPlanStepper.propTypes = {
  meter: PropTypes.object.isRequired,
  setMeter: PropTypes.func,
  user: PropTypes.object.isRequired,
};
