import {
  Button,
  Radio,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
} from '@material-ui/core';
import {
  CheckCircle,
  Help,
  HighlightOff,
  ThumbUp,
  Done,
  Speed,
  ImportantDevices,
  Bathtub,
  LocalFlorist,
  Power,
  AccountTree,
} from '@material-ui/icons';
import Axios from 'axios';
import React, {useState} from 'react';
import {useStepsStyles} from '../useStepsStyles';

export function ChooseMainSourceDataComponent({
  meter,
  isMeterOwner,
  setMeter,
  setActiveStep,
  setDisableDataCollectionBtn,
}) {
  const classes = useStepsStyles(meter);
  const [value, setValue] = useState(meter.dataSource);
  const [onSuccess, setOnSuccess] = useState(false);

  const isDisabled =
    !isMeterOwner || value === null || value === '' || value === undefined;

  const byPassDisableDataCollection = value === 'utility-portal';

  const handleRadioChange = (event) => setValue(event.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const safeValue = {
      ...meter, 
      currentStep: 1,
    };

    const postData = {
      ...safeValue,

      dataSource: value,
    };

    try {
      const dbResponse = await Axios.post(`/api/meter/${meter._id}`, postData);

      setMeter(dbResponse.data);
      if (dbResponse.status === 200) {
        setOnSuccess(true);
      }
      if (dbResponse.status === 200 && byPassDisableDataCollection) {
        setDisableDataCollectionBtn(true);
      }
      if (dbResponse.status === 200 && !byPassDisableDataCollection) {
        setDisableDataCollectionBtn(false);
      }
      setTimeout(() => {
        setActiveStep((preState) => preState + 1);
      }, 600);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Grid container direction="column" spacing={2}>
      <form onSubmit={handleSubmit}>
        <FormControl>
          <RadioGroup value={value} onChange={handleRadioChange}>
            <FormControlLabel
              label="Utility meter"
              value="meter"
              control={
                <Radio
                  icon={<Speed classes={{root: classes.btn}} />}
                  checkedIcon={<Done classes={{root: classes.btnSuccess}} />}
                />
              }
            />
            <FormControlLabel
              label="Online utility portal"
              value="utility-portal"
              control={
                <Radio
                  icon={<ImportantDevices classes={{root: classes.btn}} />}
                  checkedIcon={<Done classes={{root: classes.btnSuccess}} />}
                />
              }
            />
            <FormControlLabel
              label="Audit"
              value="audit"
              control={
                <Radio
                  icon={<AccountTree classes={{root: classes.btn}} />}
                  checkedIcon={<Done classes={{root: classes.btnSuccess}} />}
                />
              }
            />
          </RadioGroup>
          <Grid container direction="row">
            <Button
              type="submit"
              value="updatedMeter"
              className={classes.root}
              variant="outlined"
              disabled={isDisabled}
            >
              {onSuccess ? (
                <ThumbUp style={{color: 'white'}} />
              ) : isDisabled ? (
                <HighlightOff style={{color: 'grey'}} />
              ) : (
                <CheckCircle style={{color: 'white'}} />
              )}
            </Button>
            <Button
              className={classes.root}
              color="primary"
              variant="outlined"
              component="a"
              href="https://meterhero.com/source"
              target="_blank"
              rel="noopener"
            >
              <Help style={{color: 'white'}} />
            </Button>
          </Grid>
        </FormControl>
      </form>
    </Grid>
  );
}
